import React from 'react'
import { VerificationCodeForm } from '../components/form'
import { RouteComponentProps } from '@reach/router'

import SEO from '../components/shared/seo';

const VerificationCodePage = (props: RouteComponentProps) => {
  const { location } = props;

  return (
    <>
      <SEO title='Verify Page' />
      {
        location && location.state ? (
          <VerificationCodeForm locationState={location && location.state} />
        ) : (
          typeof window !== 'undefined' && window.location.replace('/404')
        )
      }
    </>
  )
}

export default VerificationCodePage;
